<template>
<div>
   <header>
    <SecondNavBar/>
    </header>
    <div class="row">
        <div class="col-md-2"></div>
        <div v-show="showIndividualForm" class="col-md-7">
            <IndividualRegisterForm/>
        </div>
        <div v-show="!showIndividualForm" class="col-md-7">
            <EnterpriseRegisterForm/>
        </div>
    </div>
</div>

    
</template>

<script>
import SecondNavBar from '../../components/LandingPage/SecondNavBar'
import IndividualRegisterForm from '../../components/LandingPage/IndividualRegisterForm'
import EnterpriseRegisterForm from '../../components/LandingPage/EnterpriseRegisterForm'

export default {
    props: ['showForm'],
    components:{
        SecondNavBar,
        IndividualRegisterForm,
        EnterpriseRegisterForm
    },
    data(){
        return{
            showIndividualForm: this.showForm
        }
    },
    methods: {
        toggleFormToShow: function(param){
            if(param === 'individual')
                this.showIndividualForm = true;
            else if(param === 'enterprise')
                this.showIndividualForm = false;
        }
    },

}
</script>

<style>

</style>