<template>
  <div class="container-fluid">
       <div class="row" id="entregister-form-header-row">
          <h4 class="purple-title" style="padding-top:20px;" id="enterprise-label-row"><strong>Enterprise User Registration</strong> </h4>
      </div>    
       <form @submit.prevent="submit(user)">  
       <div class="row mb-4">
           <label id="entregistration-code-label" class="col-sm-6 col-lg-5 col-xl-3">REGISTRATION CODE:</label>
             <input v-model="user.regCode" required type="text" class="form-control col-sm-6 offset-sm-1 offset-md-0" />
        </div>
        <div class="row form-row">
            <div class="col-xl-6 mb-4">
                <div class="row">
                    <label class="col-sm-6" for="ent-username-input">Username (your email):</label>
                    <input v-model="user.userName" required type="text" class="col-sm-6 form-control" id="ent-username-input">
                </div>
            </div>
            <div class="col-xl-6 mb-4">
                <div class="row">
                    <label class="col-sm-6 col-xl-4 offset-xl-1" for="ent-usernameconfirmed-input">Confirm Username:</label>
                    <input v-model="confirmUsername" required type="text" class="col-sm-6 form-control" id="ent-usernameconfirmed-input">
                </div>
            </div>
          
        </div>
        <div class="row form-row">
            <div class="col-xl-6 mb-4">
                <div class="row">
                    <label class="col-sm-6" for="ent-firstname-input">First Name:</label>
                    <input v-model="user.firstName" required type="text" class="col-sm-6 form-control" id="ent-firstname-input"/>
                </div>
            </div>
              <div class="col-xl-6 mb-4">
                <div class="row">
                    <label class="col-sm-6 col-xl-4 offset-xl-1" for="ent-lastname-input">Last Name:</label>
                    <input v-model="user.lastName" required type="text" class="col-sm-6 form-control" id="ent-lastname-input"/>
                </div>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-xl-6 mb-4">
                <div class="row">
                    <label class="col-sm-6" for="ent-password-input">Password:</label>
                    <input v-model="user.password" required type="password" class="col-sm-6 form-control" id="ent-password-input"/>
                </div>
            </div>
            <div class="col-xl-6 mb-4">
                <div class="row">
                    <label class="col-sm-6 col-xl-4 offset-xl-1" for="ent-confirmpassword-input">Confirm Password:</label>
                    <input v-model="user.confirmPassword" required type="password" class="col-sm-6 form-control" id="ent-confirmpassword-input"/>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            *Password must be at least 6 characters, one number, one uppercase letter, one lowercase letter, and one special character.
        </div>
        <div class="row" v-if="passwordsDoNotMatch">
            <p class="col-md-12 text-danger">
                Passwords must match
            </p>
        </div>
        <div class="row" v-if="usernamesDoNotMatch">
            <p class="col-md-12 text-danger">
                Usernames must match
            </p>
        </div>
        <div class="row" v-if="passwordDoesNotMeetRequirements">
            <p class="col-md-12 text-danger">
                Password does not meet requirements. Please see above.
            </p>
        </div>
       <div class="row form-row" style="margin-top:20px;">
                
                  <div class="col-1">
                    <input type="checkbox" id="ent-agreement-checkbox" required/>
                  </div>
                  <div class="col-11 mb-4">
                        <label for="ent-agreement-checkbox" id="agreement-checkbox-label"><small>I agree to the Home For Life Design <router-link to="/terms" target="_blank">Terms of Service and License Agreement</router-link></small></label>
                  </div>
      </div>
          <div class="row">
                <div class="col-6 col-md-4">
                    <router-link to="/">
                    <button style="margin-bottom:10px;" class="btn purple-btn btn-shadow"><FontAwesomeIcon icon="arrow-left"/>&nbsp;&nbsp;Back</button>
                    </router-link>                   
                </div>
                <div class="col-6 col-md-4 offset-md-4">
                    <button style="margin-bottom:10px;" class="btn btn-success btn-shadow">Create <FontAwesomeIcon icon="plus"/></button>
                </div>
                  
            </div>
            </form>
       <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="false"               
                ></loading>
               </div>
  </div>
</template>

<script>
import {userService} from '../../_services/index'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from '../../router/index'
export default {
    data(){
        return{
            user: {userName: "", email: "", password: "", confirmPassword: "", firstName: "", lastName: "", regCode: ""},
            //registrationCode: "",
            passwordsDoNotMatch: false,
            usernamesDoNotMatch: false,
            passwordDoesNotMeetRequirements: false,
            isLoading: false,
            confirmUsername: ""
        }
    },
    components:{
        Loading,
         FontAwesomeIcon
    },
    methods:{
        submit: function(){
            if (this.user.confirmPassword !== this.user.password) {
                this.passwordsDoNotMatch = true;
                return;
            }
            this.passwordsDoNotMatch = false;
            
            if (this.user.userName !== this.confirmUsername) {
                this.usernamesDoNotMatch = true;
                return;
            }

           if (!userService.validatePasswordRequirements(this.user.password)) {
                this.passwordDoesNotMeetRequirements = true;
                return;
           }

           this.usernamesDoNotMatch = false;
           this.user.email = this.user.userName;
           this.isLoading = true;
           userService.registerEnterpriseAccount(this.user)
           .then(() => {
               this.isLoading = false;
               this.$toast.success("Account Registered Successfully!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
                router.push("/")
           }, () => {
                 this.isLoading = false;
                  this.$toast.error("Unable to register account.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
            })
           })
        }
    }
}
</script>

<style>
</style>