<template>
<div>
  <div>
      <div class="row" id="register-form-header-row">
          <h4 class="purple-title" style="padding-top:20px;padding-bottom:10px;"><strong>Individual Account Registration</strong></h4>
      </div>
      <div class="row" id="registration-fee-row">
          
          <div class="col-sm-6">
              <h4>Registration Fee: $249 Annually</h4>
          </div>
          
      </div>
      <form @submit.prevent="submit(user)">
      <div class="row" id="registration-form-row">
          <!-- Left Col -->
          <div class="col-md-6">
              <div class="row form-row mb-4">
                  <label class="col-sm-5 " for="username-input">Username (your email):</label>                
                      <input v-model="user.userName" required type="email" class="form-control col-sm-7" id="username-input">   
              </div>
              <div class="row">
                  <p class="text-danger col-md-12">*Please use the same email that you will be using for payment.</p>
              </div>
               <div class="row form-row mb-4">
                  <label class="col-sm-5 " for="firstname-input">First Name:</label>                
                      <input v-model="user.firstName" required type="text" class="form-control col-sm-7" id="firstname-input">   
              </div>
              <div class="row form-row mb-4">
                  <label class="col-sm-5 " for="password-input">Password:</label>                
                      <input v-model = user.password required type="password" class="form-control col-sm-7" id="password-input">   
              </div>
               <div class="row form-row mb-4">
                  <label class="col-sm-5 " for="promo-input" id="promo-label">PROMO CODE:</label>                
                      <input type="text" class="form-control col-sm-3" id="promo-input"> 
                      <button style="margin-left:10px;" class="col-sm-3 btn btn-light" >Apply Code</button>  
              </div>
              
              
          </div>
           <!-- Right Col -->
          <div class="col-md-6">
              <div class="row form-row">
                  <div class="col-sm-12"></div>
              </div>
              <div class="row form-row mb-4">
                  <label class="col-sm-5 " for="last-name-input">Last Name:</label>
                  <input v-model="user.lastName" required type="text" class="form-control col-sm-7" id="last-name-input"/>
              </div>
              <div class="row form-row mb-4">
                  <label class="col-sm-5 " for="confirm-password-input">Confirm Password:</label>
                  <input v-model="user.confirmPassword" required type="password" class="form-control col-sm-7" id="confirm-password-input"/>
              </div>
              <div class="row" v-show="this.passwordsMatch == false">
                  <span class="text-red">Error. Passwords do not match.</span>
              </div>
              
          </div>
         

      </div>
      <div class="row form-row mb-4">
                  <div class="col-sm-1"></div>
                  <div class="col-sm-1">
                    <input type="checkbox" id="agreement-checkbox" required/>
                  </div>
                  <div class="col-sm-10">
                        <label for="agreement-checkbox" id="agreement-checkbox-label"><small>I agree to the Home For Life Design <router-link to="/terms" target="_blank">Terms of Service and License Agreement</router-link></small></label>
                  </div>
              </div>
          <div class="row mb-4">
                <div class="col-lg-2">
                    <router-link to="/">
                     <button style="margin-bottom:10px;" class="btn purple-btn"><FontAwesomeIcon icon="arrow-left"/>&nbsp;&nbsp;Back</button>
                    </router-link>    
                </div>
                <div class="col-lg-6"></div>  
                <div class="col-lg-4">
                     <div>
                                <stripe-checkout
                                ref="checkoutRef"
                                mode="subscription"
                                :pk="publishableKey"
                                :line-items="lineItems"
                                :success-url="successURL"
                                :cancel-url="cancelURL"
                                @loading="v => loading = v"
                                />
                                <button class="btn purple-btn" ><FontAwesomeIcon icon="plus"/>&nbsp;&nbsp;Create Account and Pay Now</button>
                     </div>
                </div>
                  
            </div>
            </form>
            <div class="row mb-4">
                <div class="col-md-8">
                    <p>This page is for <strong>individual account registration.</strong> If you are part of a University or Enterprise and need to register go to:</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <button class="btn purple-btn" @click="$parent.toggleFormToShow('enterprise')">
                       <FontAwesomeIcon icon="users"/>&nbsp;&nbsp; Enterprise/University Registration
                    </button>
                    
                </div>
            </div>
               <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="false"               
                ></loading>
               </div>
  </div>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import {userService} from '../../_services'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
     data(){
        return {
           publishableKey:"pk_test_51IEXrZL2Lb5wVp4t5DWmEzRgpDXQMygAqDkRMQnQ5q9wdgSqVFFjg3oJXMo3Oe87uqrp7nEVz9zO1kD4nCFohgSB0086lu41zi",
            loading: false,
            lineItems:[
                {
                    price: 'price_1IEYyXL2Lb5wVp4tVP2GRwq2',
                    quantity: 1
                },  
            ],
            successURL: 'https://tfltestenvironment.netlify.app/',
            cancelURL: 'https://tfltestenvironment.netlify.app/',
            user: {userName: "", email: "", password: "", confirmPassword: "", firstName: "", lastName: "" },
            passwordsMatch: true,
            isLoading: false
        }
    },
    methods:{
        submit(user){
            //check to make sure password match
            if(user.password != user.confirmPassword){
                this.passwordsMatch = false;
                return false;
            }
            else{
                this.passwordsMatch = true;
                user.email = user.userName;
                this.isLoading = true;
                userService.register(user.email, user.password, user.firstName, user.lastName)
                .then(() => {
                    this.isLoading = false;
                    this.$refs.checkoutRef.redirectToCheckout();
                }, () => {
                    this.isLoading = false;
                      this.$toast.error("Unable to register account.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
                 })
                })
                

            }
    }
    },
    components:{
       // IndividualPaymentForm
       StripeCheckout,
       Loading,
       FontAwesomeIcon
    }
}
</script>

<style>
#register-form-header-row{
    /* background-color:#e4e4e4; */
    border-bottom:3px solid #b4d88c;
    
    padding: 3px 10px 6px 12px;
    font-weight: bold;
    margin-bottom:40px;
}
#registration-fee-row{
    color: #7A7A7A;
    margin-bottom:20px;
    
}

input{
    height:34px !important;
}


#promo-label{
    color:#31708f;
}
#agreement-checkbox-label{
  margin-top:5px;
}
body{
    font-family: Droid Sans,Arial, sans-serif !important;
    
}
</style>
